(function ($) {
    'use strict';

    var pluginName = 'mhStretch',
        defaults = {
            wrapperId: 'stretch-wrapper',
            insertParent: null,
            applyFade: true,
            expandHeight: 500,
            onExpand: function () {
            },
            onContract: function () {
            }
        };

    /**
     * @param {HTMLElement} element
     * @param {Array} options
     * @constructor
     *
     * An effect that can be applied to an element that when triggered, expands from that element and calls a callback
     */
    function Plugin(element, options) {
        this.element = element;
        this.$element = $(element);
        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = pluginName;
    }

    Plugin.prototype.show = function () {
        this.initialOffset = this.$element.offset();
        var $insertInto = (this.options.insertParent && this.options.insertParent.length > 0) ? this.options.insertParent : $('body');
        var self = this;

        //  if (this.options.applyFade) {
        //    $('').appendTo($insertInto).fadeIn();
        //  }

        if ($(window).height() < this.options.expandHeight) {
            $(document).scrollTop(this.initialOffset.top);
        }

        $('<div id="' + this.options.wrapperId + '"></div>').css({
            left: this.initialOffset.left,
            //top: this.initialOffset.top,
            top: 0,
            width: this.$element.width(),
            height: this.$element.height()
        }).appendTo($insertInto).fadeIn('fast', function () {
            $(this).animate({
                width: '100%',
                //height: self.options.expandHeight,
                height: '100%',
                left: 0
            }, function () {
                $(this).css({
                    width: '100%'
                });

                self.options.onExpand();
            });
        });
    };

    Plugin.prototype.hide = function () {
        var self = this;

        if (this.options.applyFade) {
            $('.fade').fadeOut('fast', function () {
                $(this).remove();
            });
        }

        $('#' + this.options.wrapperId).animate({
            left: this.initialOffset.left,
            top: this.initialOffset.top,
            width: this.$element.width() + 'px',
            height: this.$element.height() + 'px'
        }, function () {
            $(this).fadeOut('fast', function () {
                $(this).remove();

                self.options.onContract();
            });
        });
    };

    $.fn[pluginName] = function (options) {
        var initCheck = 'plugin_' + '_' + pluginName;

        if (options === 'hide') {
            return this.data(initCheck).hide();
        }
        else if (options === 'show') {
            return this.data(initCheck).show();
        }

        return this.each(function () {
            if (!$.data(this, initCheck)) {
                $.data(this, initCheck, new Plugin(this, options));
            }
        });
    };

})(jQuery);
