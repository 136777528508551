$("#request-quote-button").click(function() {
  $([document.documentElement, document.body]).animate(
    {
      scrollTop: $("#request-quote-form").offset().top
    },
    750
  );
});

$("#page-for-home #sign-up-top-button").click(function() {
  $([document.documentElement, document.body]).animate(
    {
      scrollTop: $("#home-pricing").offset().top
    },
    750
  );
});

$("#page-districts a.contact-pilot").click(function() {
  $("#pricing select[name=choice]").val("pilot");

  $([document.documentElement, document.body]).animate(
    {
      scrollTop: $("#pricing").offset().top
    },
    750
  );
});

$("#page-districts a.contact-demo").click(function() {
  $("#pricing select[name=choice]").val("demo");

  $([document.documentElement, document.body]).animate(
    {
      scrollTop: $("#pricing").offset().top
    },
    750
  );
});
