/* NOTE: Later versions of moment-timezone.js returns UTC offsets instead of 3-character
 * timezone names when there is ambiguity (for example, "IST" is used for both India Standard Time
 * and Irish Standard Time).
 * So if user is in Dubai, "+04" is displayed instead of "GST"
 * Ambiguity is not an issue for us in practice though, so the older behaviour (which returns "GST") is desirable
 * Therefore do not use a later version of moment-timezone.js than 0.5.11
 */
$(document).ready(function() {
  const localTimeZone = moment.tz.guess();
  const localTimeZoneName = moment.tz(localTimeZone).zoneName();

  // Display local timezones
  $('.localTimezone').text(localTimeZoneName);

  // Convert UTC times to local times
  $('.timeUTC').text(function(_, time) {
    if (!time) return time;

    const localTime = moment.utc(time, "HH:mm").tz(localTimeZone).format('HH:mm');

    return localTime;
  });
});
