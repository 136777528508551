(function ($, window) {
    'use strict';

    const namespace = 'mh';
    const pluginName = 'prodigiLaunch';
    const defaults = {
            frameSrc: null,
            prodigiWidth: 938,
            prodigiHeight: 648
        };

    /**
     * @param {HTMLElement} element
     * @param {Array} options
     * @constructor
     */
    function Plugin(element, options) {
        this.element = element;
        this.$element = $(element);

        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = pluginName;

        this.$element.on('click', this.init.bind(this));
    }

    Plugin.prototype.init = function (e) {
        e.preventDefault();

        const self = this;
        const objectiveId = this.$element.data('objective-id');
        const locale = document.documentElement.lang;
        let origin = window.location.origin;

        // in case we're testing on localhost…
        if (origin.includes('localhost')) {
            origin = 'https://www.mangahigh.com';
        }

        $.get(`${origin.replace('www', 'api')}/objective/${objectiveId}`, (data) => {
            if (data.type === 'prodigi3') {
                this.frameUrl = `${origin.replace('www', 'prodigi3')}/?lang=${locale}&lesson=${objectiveId}`;
            } else {
                this.frameUrl = `${origin.replace('www', 'api')}/${locale}/px/${data.activityId}/0/0`;
            }

            if ($(window).width() < this.options.prodigiWidth || $(window).height() < this.options.prodigiHeight) {
                this.launchProdigiInTab();
            } else {
                this.stretchable = $(this.element).mhStretch({
                    expandHeight: this.options.prodigiHeight,
                    wrapperId: 'prodigi-wrapper',
                    onExpand: function () {
                        self.launchProdigiInFrame();
                    }
                });

                this.stretchable.mhStretch('show');
            }
        });
    };

    Plugin.prototype.launchProdigiInTab = function () {
        window.open(this.frameUrl);
    };

    Plugin.prototype.launchProdigiInFrame = function () {
        const self = this;
        let origin = window.location.origin;

        // in case we're testing on localhost…
        if (origin.includes('localhost')) {
            origin = 'https://www.mangahigh.com';
        }

        if (!origin) {
            origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        }

        const $prodigiX = $('<iframe id="prodigi-x" src="' + this.frameUrl + '?closeDomain=' + origin + '" frameborder="0" />')
            .css({
                'width': '100%',
                'height': '100%',
                'border': 'none'
            })
            .attr('allowFullScreen', true)
            .attr('webkitallowfullscreen', true)
            .attr('mozallowfullscreen', true)

        $(document).ready(function () {
            $(window).one('message', self.listenToClose.bind(self));
        });

        $('#prodigi-wrapper').append($prodigiX);
    };

    Plugin.prototype.listenToClose = function (e) {
        if (e.originalEvent.origin.indexOf('.mangahigh.com') === -1) {
            return;
        }

        if (e.originalEvent.data === 'closeGame') {
            this.stretchable.mhStretch('hide');
        }
    };

    // only invoke the constructor once
    $.fn[namespace + '_' + pluginName] = function (options) {
        return this.each(function () {
            const initCheck = 'plugin_' + namespace + '_' + pluginName;

            if (!$.data(this, initCheck)) {
                $.data(this, initCheck, new Plugin(this, options));
            }
        });
    };

    $(document).ready(function () {
        $('a[data-prodigi="play-prodigi"]').mh_prodigiLaunch();
        $('a[data-prodigi="play-prodigi-link"]').mh_prodigiLaunch();
    });

})(jQuery, window);
