$(document).ready(function() {
    const $pricingContainer = $('#page-for-home #home-pricing');

    const getPriceFromSubscriptionLength = (collection, length) => {
        const price = collection.filter(obj => obj.id.subscriptionLength === length)[0].displayStringMinPerStudent;
        const formattedPrice = price.replace('.00', '');

        return formattedPrice
    }

    if ($pricingContainer.length) {
        if ($pricingContainer.length) {
            const apiUrl = window.location.origin.replace("www", "api");
  
            const pricingUrl = apiUrl + $pricingContainer.data("pricing-uri");
            const organisationSource = location.pathname.toString().split('/').pop();

            let url = `${pricingUrl}?numberStudents=5&subscriptionLength[]=3&subscriptionLength[]=6&subscriptionLength[]=12`;

            if (organisationSource) {
                url += `&organisationSource=${organisationSource}`;
            }

            $.ajax({
                url: url,
                type: 'GET',
                dataType: 'json',
                headers: {
                    'MH-Client': 'Mangahigh WWW ' + CLIENT_VERSION
                },
                success: function (data) {
                    if (data.collection) {
                        $('.price-3-months', $pricingContainer).html(
                            `<span class="p display-3 font-weight-light mb-0">${getPriceFromSubscriptionLength(data.collection, 3)}</span>`
                        );

                        $('.price-6-months', $pricingContainer).html(
                            `<span class="p display-2 font-weight-light mb-0">${getPriceFromSubscriptionLength(data.collection, 6)}</span>`
                        );

                        $('.price-12-months', $pricingContainer).html(
                            `<span class="p display-3 font-weight-light mb-0">${getPriceFromSubscriptionLength(data.collection, 12)}</span>`
                        );
                    }
                }
            });
        }
    }
});
