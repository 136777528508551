const $codingForm = $("#coding-form");

$codingForm.submit(function(e) {
  e.preventDefault();

  const $fields = $("input, textarea", $codingForm);
  const submitDataArray = $codingForm.serializeArray();
  const $dataStatus = $codingForm.find(".data-status");
  const submitDataObject = {};

  $("div", $dataStatus).hide();

  $codingForm.addClass("was-validated");

  if ($codingForm[0].checkValidity() === false) {
    $(".validation-error", $dataStatus).show();
    return false;
  }

  $fields.attr("disabled", "disabled");

  submitDataArray.forEach(item => {
    submitDataObject[item.name] = item.value;
  });

  $.ajax({
    type: $codingForm.attr("method"),
    url: $codingForm.attr("action"),
    data: JSON.stringify(submitDataObject),
    success: function(data) {
      $fields.removeAttr("disabled");
      $(".success", $dataStatus).show();
      $fields.val("");
      $codingForm.removeClass("was-validated");
      $(".saving", $dataStatus).hide();
    },
    error: function(data) {
      $fields.removeAttr("disabled");
      $(".failure", $dataStatus).show();
      $codingForm.removeClass("was-validated");
      $(".saving", $dataStatus).hide();
    }
  });
});
