$('#page-districts .district-contact').submit(function() {
  const $form = $(this);
  const $fields = $('input, textarea', $form);
  const submitDataArray = $form.serializeArray();
  const $dataStatus = $form.find('.data-status');
  const initialSubmitDataObject = {};
  const finalSubmitDataObject = {};

  const apiUrl = window.location.origin.replace("www", "api");
  const contactUrl = apiUrl + $form.data("contact-uri");

  $('div', $dataStatus).hide();

  $form.addClass('was-validated');

  if ($form[0].checkValidity() === false) {
    $('.validation-error', $dataStatus).show();
    return false;
  }

  $fields.attr('disabled', 'disabled');

  for (let i = 0; i < submitDataArray.length; ++i) {
    initialSubmitDataObject[submitDataArray[i].name] = submitDataArray[i].value;
  }

  finalSubmitDataObject['name'] = initialSubmitDataObject['name'];
  finalSubmitDataObject['phone'] = initialSubmitDataObject['phone'];
  finalSubmitDataObject['email'] = initialSubmitDataObject['email'];
  finalSubmitDataObject['message'] =
    `Job Position: ${initialSubmitDataObject["position"]}, 
    District Name: ${initialSubmitDataObject["district"]}, 
    District Address: ${initialSubmitDataObject["address"]}`;

  $('.saving', $dataStatus).show();

  $.ajax({
    // Send an offer process with AJAX
    type: 'POST',
    url: contactUrl,
    data: JSON.stringify(finalSubmitDataObject),
    contentType: 'application/json',
    dataType: 'json',
    headers: {
      'MH-Client': `Mangahigh WWW ${CLIENT_VERSION}`
    },
    success: () => {



      $fields.removeAttr('disabled');
      $('.success', $dataStatus).show();
      $fields.val('');
      $form.removeClass('was-validated');
      $('.saving', $dataStatus).hide();
    },
    error: () => {
      $fields.removeAttr('disabled');
      $('.failure', $dataStatus).show();
      $form.removeClass('was-validated');
      $('.saving', $dataStatus).hide();
    }
  });

  return false;
});
