/**
 * When a modal containing a <video> is opened, force autoplay of the video.
 * 
 * Motivation: The <video> element's `autoplay` attribute is often blocked by browsers (https://developer.mozilla.org/en-US/docs/Web/Media/Autoplay_guide)
 * which we need to work around as user will expect video to play after clicking the big "play" button that opens the modal.
 */
(function () {
  const modals = document.querySelectorAll('.modal');

  // There may be more than one modal element to target (such as one for each video on the page, eg. https://www.mangahigh.com/distance-learning-teacher-resources)
  modals.forEach(modal => {
    const video = modal.querySelector('video');
    if (video == null) return;

    // Use Bootstrap's custom events 'shown.bs.modal' and 'hidden.bs.modal'
    // https://getbootstrap.com/docs/4.0/components/modal/#events
    $(modal)
      .on("shown.bs.modal", () => {
        video.play();
      })
      .on("hidden.bs.modal", () => {
        video.pause();
        video.currentTime = 0;
      });
  });
})();
