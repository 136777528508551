$(document).ready(function() {
  const $contactForm = $(".contact-form");
  if ($contactForm.length) {
    const apiUrl = window.location.origin.replace("www", "api");
    const costUrl = apiUrl + $contactForm.data("cost-uri");
    const countryUrl = apiUrl + $contactForm.data("country-uri");

    $contactForm.on("submit", function() {
      const $form = $(this);
      const $fields = $("input, textarea, select", $form);
      const submitDataArray = $form.serializeArray();
      const $dataStatus = $form.find(".data-status");
      const submitDataObject = {};

      $("div", $dataStatus).hide();

      $form.addClass("was-validated");

      if ($form[0].checkValidity() === false) {
        $(".validation-error", $dataStatus).show();
        return false;
      }

      $fields.attr("disabled", "disabled");

      submitDataArray.forEach(item => {
        submitDataObject[item.name] = item.value;
      });

      $(".saving", $dataStatus).show();

      $.ajax({
        // Send an offer process with AJAX
        type: "POST",
        url: costUrl,
        data: JSON.stringify(submitDataObject),
        contentType: "application/json",
        dataType: "json",
        headers: {
          "MH-Client": "Mangahigh WWW " + CLIENT_VERSION
        },
        crossDomain: true,
        success: () => {
   

          $fields.removeAttr("disabled");
          $(".success", $dataStatus).show();
          $fields.val("");
          $form.removeClass("was-validated");
          $(".saving", $dataStatus).hide();
        },
        error: () => {
          $fields.removeAttr("disabled");
          $(".failure", $dataStatus).show();
          $form.removeClass("was-validated");
          $(".saving", $dataStatus).hide();
        }
      });

      return false;
    });

    var $countryDropdown = $("#contact-form-country"),
      $regionDropdown = $("#contact-form-region"),
      $regionSection = $("#contact-form-region-section"),
      countryToRegionUrlMap = {},
      selectionCountry = "",
      regionRel,
      regionUrl;

    $regionSection.hide();

    $.ajax({
      url: countryUrl,
      type: "GET",
      dataType: "json",
      headers: {
        "MH-Client": "Mangahigh WWW " + CLIENT_VERSION
      },
      success: data => {
        data.collection.map(item => {
          $countryDropdown.append(
            $("<option></option>")
              .attr("value", item.id)
              .text(item.name)
          );
          regionRel = item.links.find(link => link.rel === "regions");
          regionUrl = regionRel.uri;
          countryToRegionUrlMap[item.id] = regionUrl;
          selectionCountry = item.id;
        });
      }
    });

    $countryDropdown.change(function() {
      $regionSection.hide();
      $regionDropdown.removeAttr("required");
      $("option:enabled", $regionDropdown).remove();

      $.ajax({
        url: apiUrl + countryToRegionUrlMap[$(this).val()],
        type: "GET",
        dataType: "json",
        headers: {
          "MH-Client": "Mangahigh WWW " + CLIENT_VERSION
        },
        success: data => {
          data.collection.map(item => {
            $regionDropdown.append(
              $("<option></option>")
                .attr("value", item.nameShort)
                .text(item.name)
            );
          });

          $("option:not(:enabled)", $regionDropdown).attr("selected", true);

          if (data.collection.length) {
            $regionDropdown.attr("required", true);
            $regionSection.show();
          }
        }
      });
    });
  }
});
