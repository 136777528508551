/* jshint camelcase: false */
/* global swfobject */
//jscs:disable requireCamelCaseOrUpperCaseIdentifiers

(function ($, window) {
    'use strict';

    var gameData = {
        flowerpower: {
            gameId: 1,
            version: 'flowerpower',
            javascript: true,
            desiredDimensions: {
                width: 1024,
                height: 768
            }
        },
        flowerpowerlite: {
            gameId: 9,
            version: 'flowerpowerlite',
            javascript: true,
            desiredDimensions: {
                width: 1024,
                height: 768
            }
        },
        iceicemaybe: {
            gameId: 6,
            version: 'iceicemaybe',
            hasIhs: false,
            wrapperVersion: '2.40a_1409'
        },
        transtar: {
            gameId: 7,
            version: 'transtar',
            hasIhs: true,
            wrapperVersion: '060'
        },
        transtarhtml5: {
            gameId: 7,
            version: 'transtarhtml5',
            javascript: true,
            desiredDimensions: {
                width: 1024,
                height: 768
            }
        },
        roboops: {
            gameId: 27,
            version: 'roboops',
            hasIhs: false,
            javascript: true,
            desiredDimensions: {
                width: 1024,
                height: 768
            }
        },
        sigmaprime: {
            gameId: 12,
            version: 'sigmaprime',
            hasIhs: false,
            wrapperVersion: '060'
        },
        sigmaprimelite: {
            gameId: 14,
            version: 'sigmaprimelite',
            hasIhs: false,
            wrapperVersion: '060'
        },
        sundaetimes: {
            gameId: 15,
            version: 'sundaetimes',
            javascript: true,
            desiredDimensions: {
                width: 1024,
                height: 768
            }
        },
        sundaetimeslite: {
            gameId: 18,
            version: 'sundaetimeslite',
            javascript: true,
            desiredDimensions: {
                width: 1024,
                height: 768
            }
        },
        wrecksfactor: {
            gameId: 16,
            version: 'wrecksfactor',
            hasIhs: true,
            wrapperVersion: '2.40a_1409'
        },
        atangledweb: {
            gameId: 17,
            version: 'atangledweb',
            hasIhs: true,
            wrapperVersion: '060'
        },
        pinatafever: {
            gameId: 19,
            version: 'pinatafever',
            hasIhs: true,
            wrapperVersion: '060'
        },
        jetstreamriders: {
            gameId: 20,
            version: 'jetstreamriders',
            javascript: true,
            desiredDimensions: {
                width: 1024,
                height: 768
            }
        },
        jabara: {
            gameId: 21,
            version: 'jabara',
            javascript: true,
            desiredDimensions: {
                width: 1024,
                height: 768
            }
        },
        deepestocean: {
            gameId: 23,
            version: 'deepestocean',
            javascript: true,
            desiredDimensions: {
                width: 1024,
                height: 768
            }
        },
        iceicemaybehtml5: {
            gameId: 25,
            version: 'iceicemaybehtml5',
            javascript: true,
            desiredDimensions: {
                width: 1024,
                height: 768
            }
        },
        wrecksfactorhtml5: {
            gameId: 26,
            version: 'wrecksfactorhtml5',
            javascript: true,
            desiredDimensions: {
                width: 1024,
                height: 768
            }
        },
        pyramidpanichtml5: {
            gameId: 28,
            version: 'pyramidpanichtml5',
            javascript: true,
            desiredDimensions: {
                width: 1024,
                height: 768
            }
        },
        graphsofthegalaxy: {
          gameId: 29,
          version: 'graphsofthegalaxy',
          javascript: true,
          desiredDimensions: {
              width: 1024,
              height: 768
          }
        },
        bubblefunction: {
            gameId: 30,
            version: 'bubblefunction',
            javascript: true,
            desiredDimensions: {
                width: 1024,
                height: 768
            }
        },
        littleraincloud: {
            gameId: 31,
            version: 'littleraincloud',
            javascript: true,
            desiredDimensions: {
                width: 1024,
                height: 768
            }
        },
        minusminers: {
            gameId: 33,
            version: 'minusminers',
            javascript: true,
            desiredDimensions: {
                width: 1024,
                height: 768
            }
        },
        ticktocktrain: {
            gameId: 35,
            version: 'ticktocktrain',
            javascript: true,
            desiredDimensions: {
                width: 1024,
                height: 768
            }
        }
    };

    var defaultIframeHeight = 700;

    var namespace = 'mh',
        pluginName = 'gameLaunch',
        defaults = {
            width: 910,
            height: 600,
            flashVersionRequired: 9
        };

    /**
     * @param {HTMLElement} element
     * @param {Array} options
     * @constructor
     */
    function Plugin(element, options) {
        this.element = element;
        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = pluginName;

        var dataAttrs = $(this.element).data();
        var gData = gameData[dataAttrs.gameName];

        $(this.element).off('click').on('click', this.init.bind(this));
    }

    Plugin.prototype.getEmbedUrl = function () {
        var origin = window.location.origin;

        // in case we're testing on localhost...
        if (origin.includes('localhost')) return 'https://embed.mangahigh.com';
        
        // in case we're testing on minikube...
        if (origin.includes('minikube')) return 'https://embed.mangahigh.com';

        return origin.replace('www', 'embed');
    };

    Plugin.prototype.init = function (e) {
        // save a reference to this click in the plugin for when the game is closed
        $.fn[namespace + '_' + pluginName].previousElement = this.element;

        var dataAttrs = $(this.element).data();
        var gData = gameData[dataAttrs.gameName];

        // Flash games are always loaded in the current page. Check if JS game can be launched like this also

        var newWindow = gData.desiredDimensions && (screen.width <= gData.desiredDimensions.width || screen.height <= gData.desiredDimensions.height);

        if (gData.javascript && newWindow) {
            this.launchGame(newWindow, dataAttrs);
        }
        else {
            var height = defaultIframeHeight;
            var self = this;

            if (gData.desiredDimensions && gData.desiredDimensions.height) {
                height = gData.desiredDimensions.height;
            }

            this.stretchable = $(this.element).parent().mhStretch({
                // this.stretchable = $(document.body) .mhStretch({
                expandHeight: height,
                wrapperId: 'game-wrapper',
                onExpand: function () {
                    self.launchGame(false, dataAttrs);
                }
            });

            this.stretchable.mhStretch('show');
        }

        e.preventDefault();
    };

    Plugin.prototype.launchGame = function (newWindow, dataAttrs) {
        var gameName = dataAttrs.gameName;

        var locale = document.documentElement.lang;
        var url = this.getEmbedUrl() + '/' + gameName + '?locale=' + locale + '&guestMode=true';

        if (dataAttrs.activityId) {
            url += '&activityId=' + dataAttrs.activityId;
        }

        if (newWindow) {
            window.open(url);
        }

        else {
            var iframeHeight = defaultIframeHeight;

            if (gameData[gameName].desiredDimensions) {
                iframeHeight = gameData[gameName].desiredDimensions.height;
            }

            var iframehtml = '<iframe src="' + url + '" width="100%" height="100%"></iframe><span class="close"></span>';
            $('#game-wrapper').append(iframehtml).addClass('closable');
            $('#game-wrapper .close').on('click touchstart', window.gameQuit);
        }
    };

    // expose gameQuit to the global namespace
    window.gameQuit = function () {
        var element = $.fn[namespace + '_' + pluginName].previousElement;
        var self = $(element).data('plugin_' + namespace + '_' + pluginName);

        self.stretchable.mhStretch('hide');
    };

    // only invoke the constructor once
    $.fn[namespace + '_' + pluginName] = function (options) {
        return this.each(function () {
            var initCheck = 'plugin_' + namespace + '_' + pluginName;

            if (!$.data(this, initCheck)) {
                $.data(this, initCheck, new Plugin(this, options));
            }
        });
    };

    $(document).ready(function () {
        $('a[data-game="play-game"]').mh_gameLaunch();
        $('a[data-game="play-game-link"]').mh_gameLaunch();
    });

})(jQuery, window);
