$(document).ready(function() {
  // Find the form by its ID
  const $forHomeForm = $("#for-home-form");

  // Get API url for generating list of countries
  // (api.mangahigh.com/country OR alpha-api.mangahigh.com/country OR api-minikube.mangahigh.com/country)
  const apiUrl = window.location.origin.replace("www", "api");
  const countryUrl = apiUrl + '/country';

  // Handle form submission
  $forHomeForm.submit(function(e) {
    e.preventDefault();

    const $fields = $("input, textarea", $forHomeForm);
    const submitDataArray = $forHomeForm.serializeArray();
    const $dataStatus = $forHomeForm.find(".data-status");
    const submitDataObject = {};

    $("div", $dataStatus).hide();

    $forHomeForm.addClass("was-validated");

    if ($forHomeForm[0].checkValidity() === false) {
      $(".validation-error", $dataStatus).show();
      return false;
    }

    $fields.attr("disabled", "disabled");

    submitDataArray.forEach(item => {
      submitDataObject[item.name] = item.value;
    });

    $.ajax({
      type: $forHomeForm.attr("method"),
      url: $forHomeForm.attr("action"),
      data: JSON.stringify(submitDataObject),
      success: function(data) {
        $fields.removeAttr("disabled");
        $(".success", $dataStatus).show();
        $fields.val("");
        $forHomeForm.removeClass("was-validated");
        $(".saving", $dataStatus).hide();
      },
      error: function(data) {
        $fields.removeAttr("disabled");
        $(".failure", $dataStatus).show();
        $forHomeForm.removeClass("was-validated");
        $(".saving", $dataStatus).hide();
      }
    });
  });

  // Populate country dropdown menu
  const $countryDropdown = $("#contact-form-country");
  $.ajax({
    url: countryUrl,
    type: "GET",
    dataType: "json",
    headers: {
      "MH-Client": "Mangahigh WWW " + CLIENT_VERSION
    },
    success: data => {
      data.collection.map(item => {
        $countryDropdown.append(
          $("<option></option>")
            .attr("value", item.id)
            .text(item.name)
        );
      });
    }
  });
});
